<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      @resetBtnClick="handleResetClick"
      :pageInfo="pageInfo"
    >
      <template #customForm>
        <el-col :span="24" style="padding: 18px">
          <el-form>
            <el-form-item label="Parameter" required>
              <el-select
                style="width: 100%"
                v-model="selectedItems"
                placeholder=" "
                disabled
                multiple
              >
                <el-option
                  v-for="item in listData"
                  :key="item.id"
                  :label="item.key"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
          <LTable
            ref="parameterList"
            :selectedItems="selectedIds"
            :isViewPage="currentPageType === 'view'"
            :templateType="templateType"
            :selectedParameterData="selectedParameterData"
          ></LTable>
        </el-col>
      </template>
    </page-form>
  </div>
</template>
<script setup>
import pageForm from '@/components/PageForm/page-form.vue'

import LTable from './dynamic-table2.vue'
import { baseNewFormConfig } from './config/new'
import { baseUpdateFormConfig } from './config/edit'
import { baseViewFormConfig } from './config/view'
import { ElMessage } from 'element-plus'
import {
  ref,
  getCurrentInstance,
  computed,
  watch,
  onMounted,
  onUnmounted
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'

const routes = useRoute()
const router = useRouter()
const { t } = i18n.global

//  1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    //  view routesName 跟你配置路由的name相关
    routesName: 'TemplateView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('general.router-template-view'),
    pageType: 'view'
  },
  {
    routesName: 'TemplateEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('general.router-template-edit'),
    pageType: 'edit',
    //  edit page特有的属性 reset时不将表单置为空
    isResetFields: false
  },
  {
    routesName: 'TemplateNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('general.router-template-new'),
    pageType: 'add'
  }
]
//  2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()
//  3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig

const currentPageType = ref(pageType)
const baseFormConfigRef = computed(() => {
  return baseFormConfig
})
//  4、区分当前页面是否需要转化options选项
const configOptions = ref({})
currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig
const { appContext } = getCurrentInstance()

const id = routes.params.id
const bus = appContext.config.globalProperties.$bus
const listData = ref([])
const templateType = ref('00')
const handleChange = (form) => {
  const currentTemplateType = form.templateType
  templateType.value = currentTemplateType
  getParameterList(templateType.value)
}
const getParameterList = async (type) => {
  listData.value = await store.dispatch('param/getAllParameters', type)
}
onMounted(async () => {
  bus.on('linkageOptionsChange', handleChange)
  await getParameterList(templateType.value)
})
onUnmounted(() => {
  bus.off('linkageOptionsChange', handleChange)
})
const pageInfo = ref({})
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const selectedIds = ref([])
const selectedParameterData = ref([])
//  5、定义方法获取当前页面的初始化数据
const getCurrentPageInfo = async () => {
  const templateInfo = await store.dispatch('param/getTemplateById', {
    id
  })
  await getParameterList(templateType.value)
  selectedIds.value = templateInfo?.paramId?.split(',')
  selectedItems.value = selectedIds.value?.map((item) => {
    return Number(item)
  })
  selectedParameterData.value = Object.keys(
    JSON.parse(templateInfo?.parameter)
  )?.map((key) => {
    const currentId = key?.split('@')[0]
    return {
      id: currentId,
      value: JSON.parse(templateInfo?.parameter)[key]
    }
  })
  templateType.value = templateInfo?.templateType
  getParameterList(templateInfo?.templateType)
  pageInfo.value = templateInfo
}

//  6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}
const parameterList = ref(null)
const selectedItems = ref([])
watch(
  () => parameterList?.value?.selectedListData,
  (newVal) => {
    if (newVal) {
      selectedItems.value = newVal?.map((item) => item.id)
    }
  }
)

//  7、定义被提交的方法
const handleSubmit = async (data) => {
  // 调用校验函数
  const parameterResult = await parameterList.value.validateAllForms()
  if (!parameterResult) {
    ElMessage.error('Please check the fields in the parameter list')
    return
  }
  // 获取参数列表的数据
  const parameter = parameterList.value.selectedRowItems
  console.log(parameter)

  if (Array.isArray(parameter) && parameter.length === 0) {
    ElMessage.error('Parameter is required')
    return
  }
  const obj = {}
  parameter.forEach((item, index) => {
    const key = item.id + '@' + item.key
    obj[key] = item.value
  })
  data.ids = parameterList.value.selectedRowItems.map((item) => item.id)
  data.types = parameterList.value.selectedRowItems.map((item) => item.type)
  data.parameter = JSON.stringify(obj)
  let url = 'handleAddTemplate'
  const form = { ...data }
  if (pageType === 'edit') {
    url = 'handleUpdateTemplate'
    form.id = id
  }

  handleMessageCommit(`param/${url}`, form).then(() => {
    router.push('/template/overview')
  })
}
// 重置parameterList
const handleResetClick = async () => {
  selectedItems.value = []
  // 调用子组件的清空勾选的函数
  parameterList.value.clearSelection()
  // 调用子组件的重置parameter的函数
  if (currentPageType.value === 'add') {
    await parameterList.value.handleResetParameterList()
  } else if (currentPageType.value === 'edit') {
    await parameterList.value.handleResetAndInitCheckedParameters()
  }
}
</script>

<style scoped></style>
