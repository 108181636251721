import { getCommonParamsOptions } from '@/utils/common'

export const baseViewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'param.template-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'templateType',
      type: 'select',
      label: 'param.template-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('template_type'),
      otherOptions: {
        disabled: true
      }
    },
    // {
    //   field: 'parameter',
    //   type: 'input',
    //   label: 'param.parameter',
    //   otherOptions: {
    //     disabled: true
    //   }
    // },
    // {
    //   field: 'md5',
    //   type: 'input',
    //   label: 'param.md5',
    //   otherOptions: {
    //     disabled: true
    //   }
    // },
    // {
    //   field: 'sha256',
    //   type: 'input',
    //   label: 'param.sha256',
    //   otherOptions: {
    //     disabled: true
    //   }
    // },
    {
      field: 'status',
      type: 'select',
      label: 'common.app-status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 200,
        disabled: true
      }
    }
    // {
    //   field: 'parameter',
    //   type: 'textarea',
    //   label: 'param.parameter',
    //   otherOptions: {
    //     maxlength: 200,
    //     disabled: true
    //   }
    // }
  ],
  validateRules: {}
}
