import { getCommonParamsOptions } from '@/utils/common'

export const baseUpdateFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'param.template-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'templateType',
      type: 'select',
      label: 'param.template-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('template_type'),
      otherOptions: {
        disabled: true
      }
    },
    // {
    //   field: 'parameter',
    //   type: 'input',
    //   label: 'param.parameter'
    // },
    // {
    //   field: 'md5',
    //   type: 'input',
    //   label: 'param.md5'
    // },
    // {
    //   field: 'sha256',
    //   type: 'input',
    //   label: 'param.sha256'
    // },
    // {
    //   field: 'status',
    //   type: 'select',
    //   label: 'common.app-status',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getCommonParamsOptions('status')
    // },
    {
      field: 'description',
      type: 'textarea',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500
      }
    }
    // {
    //   field: 'parameter',
    //   type: 'textarea',
    //   label: 'param.parameter',
    //   colLayout: {
    //     xl: 24,
    //     lg: 24,
    //     md: 24,
    //     sm: 24,
    //     xs: 24
    //   },
    //   otherOptions: {
    //     // maxlength: 500
    //   }
    // }
  ]
}
