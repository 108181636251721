import { getCommonParamsOptions } from '@/utils/common'

export const baseNewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'param.template-name',
      otherOptions: {
        maxlength: 80
      }
    },
    {
      field: 'templateType',
      type: 'select',
      label: 'param.template-type',
      isResolveGlobalParams: true,
      initialValue: '00',
      options: [],
      isLinkageOptions: true,
      handler: () => getCommonParamsOptions('template_type')
    },
    // {
    //   field: 'parameter',
    //   type: 'input',
    //   label: 'param.parameter'
    // },
    // {
    //   field: 'md5',
    //   type: 'input',
    //   label: 'param.md5'
    // },
    // {
    //   field: 'sha256',
    //   type: 'input',
    //   label: 'param.sha256'
    // },
    // {
    //   field: 'status',
    //   type: 'select',
    //   label: 'common.app-status',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getCommonParamsOptions('status')
    // },
    // {
    //   field: 'parameter',
    //   type: 'select',
    //   label: 'param.parameter'
    // },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',

      otherOptions: {
        maxlength: 200
      }
    }
  ],
  validateRules: {
    name: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    templateType: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
    // parameter: [
    //   {
    //     required: true,
    //     message: 'general.required',
    //     trigger: 'change'
    //   }
    // ]
  }
}
